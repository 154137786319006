.widgetButton {
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 21px;
  background: transparent;
  cursor: pointer;
  clip-path: circle(100%);
  position: relative;
  padding: 0;
  margin: 8px;
  animation: enterRight 1.5s 1;

  & > svg {
    position: absolute;
    top: 0;
    left: 0;
  }
  & .iconChatbot {
    transition: transform 120ms, opacity 150ms;
  }
  & .iconChatbot.opened {
    transform: rotate(90deg);
    opacity: 0;
  }
}

.iconClose {
  height: 50px;
  width: 50px;
}

@keyframes enterRight {
  0% {
    right: -100px;
  }

  100% {
    right: 0;
  }
}

.chatbotAvatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.3);
  }
  100% {
    box-shadow: 0 0 0 7px rgba(0, 0, 0, 0);
  }
}
