@value (colorDefaultText, colorLightGray, colorDarkGray, colorDarkerBorder, colorGray9, colorWhite, colorBlue, red) from 'styles/colors.module.css';
@value maxMobile from 'styles/breakpoints.module.css';

@value avatarSize: 40px;
@value avatarMargin: 24px;


.root {
    flex-flow: column;
    padding: 0;

    &>* {
        flex: 0 0 auto;
    }

    &>*:first-child {
        margin-top: 30px;
    }

    @media maxMobile {

        & .container {
            padding: 0 15px;
        }
    }
}

.container {
    padding: 0 45px;
}

.containerIncoming {
    width: 100%;
}

.containerOutgoing {
    flex-flow: row-reverse;
}

.namedAvatar {
    flex-flow: column;
    margin-right: avatarMargin;
    align-items: center;
}

.namedAvatarOutgoing {
    margin-right: 0;
}

.avatar {
    width: avatarSize;
    height: avatarSize;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: bold;
}

.avatarOutgoing {
    composes: backgroundGray from 'styles/colors.module.css';
    margin-right: 0;
    margin-left: avatarMargin;
    border-radius: 50%;
}

.bubble {
    composes: message from 'styles/typography.module.css';
    max-width: 300px;
    padding: 10px;
    margin-bottom: 8px;
    border-radius: 18px;
    white-space: pre-line;
    flex-flow: column;
}

.bubbleCenter {
    composes: bubble;
    margin: 0 auto;
}

.bubbleError {
    composes: bubbleCenter;
    background-color: red;
}

.bubbleIncoming {
    composes: bubble;
    composes: backgroundGray from 'styles/colors.module.css';
}

.bubbleOutgoing {
    composes: bubble;
    composes: backgroundLightBlue from 'styles/colors.module.css';
}

.isAutofilled {
    composes: backgroundBlue from 'styles/colors.module.css';
}

.favicon {
    width: avatarSize;
    height: avatarSize;
    border-radius: 50%;
}

/* accessory styles */

.jobMatchRoot {
    flex-flow: column;
    align-items: flex-start;
    border: 2px solid colorLightGray;
    border-radius: 5px;
    padding: 20px;
    font-size: 14px;
    flex: 1 1 auto;

    &>*+* {
        margin-top: 16px;
    }

    @media maxMobile {
        flex: 1 1 auto;
        max-width: 500px;
    }
}

.titleRow {
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
}

.linkText {
    composes: bold title from 'styles/typography.module.css';
    color: colorBlue;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
}

.jobRow {
    flex-flow: column;
}

.customJobResultWithLink {
    color: unset;
    font-size: 14px;
    text-decoration: none;
    margin-bottom: 12px;

    & a {
        color: colorBlue;
        text-decoration: none;
        font-weight: bold;
    }

}

.linkText {
    composes: bold title from 'styles/typography.module.css';
    color: colorBlue;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
}