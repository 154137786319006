.flexListGrow {
  & > * {
    flex: 1 0 0%;
  }
}
.flexListAuto {
  & > * {
    flex: 0 0 auto;
  }
}
.flexGrow {
  flex: 1 0 0%;
}
.flexAuto {
  flex: 0 0 auto;
}
.flexColumn {
  flex-direction: column;
}

.buttonList {
  flex-flow: row wrap;
  max-width: 500px;
}
.buttonList > * {
  margin: 8px 8px 0 0;
}
