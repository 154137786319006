@value (colorBlue) from 'styles/colors.module.css';
@value maxMobile from 'styles/breakpoints.module.css';

.jobSummary {
    flex-flow: column;
    cursor: pointer;
    padding: 16px 20px;
    
    &:hover {
        background: rgba(0, 0, 0, 0.1);
    }

    @media maxMobile {
        padding: 16px 0;
    }
}

.jobTitle {
    composes: bold from 'styles/typography.module.css';
    color: colorBlue;
    cursor: pointer;
    text-decoration: none;
}