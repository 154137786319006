@value (
  colorBlue
  , colorDefaultText
) from 'styles/colors.module.css';
@value (
  maxMobile
) from 'styles/breakpoints.module.css';

.bg {
  flex: 1 1 auto;
  min-height: 100%;
  min-width: 100%;
  align-items: center;
  justify-content: center;
}

.blueBg {
  background: colorBlue;
}

.center {
  align-items: center;
  flex-flow: row wrap;
  padding: 20px;

  @media maxMobile {
    justify-content: center;

    & > svg {
      display: none;
    }
  }
}

.content {
  margin: 25px;
  flex-flow: column;
  max-width: 540px;

  & > h1 {
    font-size: 180px;
    font-weight: bold;
    color: colorBlue;
  }

  & > h2 {
    font-size: 45px;
    font-weight: bold;
  }

  & > p {
    font-size: 20px;
    font-weight: bold;
    color: colorDefaultText;
  }
}
