.body {
  display: flex;
  flex-flow: column;
  height: 100%;

  & div,
  & header {
    display: inherit;
  }

  & * {
    box-sizing: border-box;
    font-family: 'proxima-nova', 'Helvetica Neue', sans-serif;
  }
}