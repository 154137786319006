@value (widthMobile, minMobile, maxMobile) from 'styles/breakpoints.module.css';
@value (colorGreen, colorPink, colorWhite, colorPurple) from 'styles/colors.module.css';

.content {
    composes: dialog from 'styles/elevation.module.css';
    composes: flexListGrow from 'styles/ui.module.css';
    overflow-y: hidden;

    @media minMobile {
        border-radius: 4px;
    }

    @media maxMobile {
        box-shadow: none;
        flex: 1 0 0%;
    }
}


.bg {
    flex-grow: 1;
    height: 100%;
    padding: 50px 0;

    &>* {
        flex: 0 1 widthMobile;
        margin: 0 auto;
    }
}

.footer {
    align-self: center;
}

.footerText {
    composes: text from 'styles/typography.module.css';
    margin-bottom: 18px;
}

.footerLogo {
    height: 20px;
    width: 60px;
    margin-left: 0.25em;
    align-items: center;
}