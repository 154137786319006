@value maxMobile from 'styles/breakpoints.module.css';
@value (colorDarkGray, colorLightGray) from 'styles/colors.module.css';

@value avatarSize: 40px;
@value avatarMobileSize: 25px;
@value avatarMargin: 24px;

.root {
  flex-flow: column;
  padding: 0;

  & > * {
    flex: 0 0 auto;
  }

  /* first element in chat (usually the timestamp)
  gets a bit of padding up top so it doesn't look
  too awkward */
  & > *:first-child {
    margin-top: 74px;
  }

  @media maxMobile {
    & .messageContainerOutgoing,
    & .messageContainerIncoming {
      padding: 0 15px;
    }
  }
}

.timestampSubtitle {
  composes: subtitle from 'styles/typography.module.css';
  composes: textGray from 'styles/colors.module.css';
  margin: 24px 0;
  text-align: center;
}

.timestamp {
  composes: timestamp from 'styles/typography.module.css';
  composes: textGray from 'styles/colors.module.css';
  margin-top: 7px;
}

.messageContainerLoading {
  margin-bottom: 25px;
}

.messageContainerOutgoing {
  flex-flow: row-reverse;
  padding: 0 45px;
}
.messageContainerIncoming {
  width: 100%;
  padding: 0 45px;
}

.messageContainerSimple {
  padding-left: calc(avatarSize + avatarMargin);
}

.bareAccessoryContainer {
  padding: 0;
}

.favicon {
  width: avatarSize;
  height: avatarSize;
  border-radius: 50%;

  /* @media maxMobile {
    height: 15px;
    width: 15px;
  } */
}

.namedAvatar {
  flex-flow: column;
  margin-right: avatarMargin;
  align-items: center;
}
.namedAvatarOutgoing {
  margin-right: 0;
}

.avatar {
  width: avatarSize;
  height: avatarSize;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: bold;

  /* @media maxMobile {
    width: avatarMobileSize;
    height: avatarMobileSize;

    & svg {
      transform: scale(calc(25 / 30));
    }
  } */
}
.avatarFirst {
  composes: backgroundGray from 'styles/colors.module.css';
}
.avatarOutgoing {
  margin-right: 0;
  margin-left: avatarMargin;
}

.avatarName {
  composes: timestamp from 'styles/typography.module.css';
}

.message {
  flex-flow: column;
  margin-bottom: 8px;
  border-radius: 18px;
}
.incoming {
  align-items: flex-start;
}
.outgoing {
  align-items: flex-end;
}
.last {
  margin-bottom: 16px;
}

.feedback {
  composes: textGray from 'styles/colors.module.css';
  font-size: 15px;
  flex-flow: column;
  margin-bottom: 8px;
  border-radius: 18px;
  padding-left: calc(avatarSize + avatarMargin);
}

.feedbackOption {
  margin-left: 8px;
  cursor: pointer;
}

.bubble {
  composes: message from 'styles/typography.module.css';
  max-width: 300px;
  padding: 10px;
  border-radius: 18px;
  white-space: pre-line;
  flex-flow: column;
}
.bubbleIncoming {
  composes: bubble;
}
.bubbleOutgoing {
  composes: backgroundLightBlue from 'styles/colors.module.css';
  composes: bubble;
}
.bubbleMarkdown {
  composes: bubbleIncoming;
  display: block !important;

  & li {
    margin: 0.5em 0;
  }
}

.circle {
  animation: typingColors 0.6s ease alternate infinite;
}

@keyframes typingColors {
  from {
    fill: #848484;
  }
  to {
    fill: #c4c4c4;
  }
}

.gif {
  width: 280px;
}

.richText {
  max-width: 300px;
  padding: 10px;
  font-size: 14px;
  line-height: 17px;
}
