@value boxShadowColor1: rgba(0, 0, 0, 0.24);
@value boxShadowColor2: rgba(0, 0, 0, 0.12);

@value neg4: inset 0px 4px 5px boxShadowColor1, inset 0px 2px 8px boxShadowColor2;
@value neg2: inset 0px 2px 3px boxShadowColor1, inset 0px 1px 8px boxShadowColor2;
@value neg1: inset 0px 1px 1px boxShadowColor1, inset 0px 0px 4px boxShadowColor2;
@value pos1: 0px 1px 1px boxShadowColor1, 0px 0px 4px boxShadowColor2;
@value pos2: 0px 2px 3px boxShadowColor1, 0px 1px 8px boxShadowColor2;
@value pos4: 0px 4px 5px boxShadowColor1, 0px 2px 16px boxShadowColor2;
@value pos6: 0px 6px 8px boxShadowColor1, 0px 3px 24px boxShadowColor2;
@value pos8: 0px 8px 12px boxShadowColor1, 0px 4px 32px boxShadowColor2;
@value pos12: 0px 12px 16px boxShadowColor1, 0px 6px 48px boxShadowColor2;

.input {
  box-shadow: neg2;
}
.disabled, .active {
  box-shadow: neg1;
}
.card, .switch {
  box-shadow: pos1;
}
.button, .menu {
  box-shadow: pos2;
}
.tooltip {
  box-shadow: pos4;
}
.hover {
  box-shadow: pos6;
}
.panel {
  box-shadow: pos8;
}
.modal, .dialog {
  box-shadow: pos12;
}
