/* colors */
@value colorWhite: #ffffff;
@value colorLightGray: #f7f7f7;
@value colorBlack: #000000;
@value colorDarkGray: #919191;
@value colorDefaultText: #444444;
@value colorDarkerBorder: #dddddd;

@value colorLightBlue: #e0f3ff;
@value colorBlue: #007faf;
@value colorGreen: #2E855C;
@value colorTurquoise: #07837D;
@value colorPink: #DACCFF;
@value colorPurple: #5C34CD;

/* used in feedback module */
@value red: #ffa39f;
@value orange: #ffc48d;
@value yellow: #ffeba2;
@value lime: #c0eaa0;
@value green: #a0eaa2;

/* functions */
.backgroundGray {
  background: colorLightGray;
}
.backgroundLightBlue {
  background: colorLightBlue;
}
.backgroundBlue {
  background: colorBlue;
  color: colorWhite;
}
.backgroundWhite {
  background: colorWhite;
}
.textGray {
  color: colorDarkGray;
}
.textBlue {
  color: colorBlue;
}
