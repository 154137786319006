@value buttonDefaultShadow from '../../@spaced-out/components/button/button.module.css';
@value mqMaxMobile from 'styles/breakpoints.module.css';

@value color0: #FFA39F;
@value color1: #FFC48D;
@value color2: #FFEBA3;
@value color3: #C0EAA0;
@value color4: #A0EAA2;

.list.list {
  display: grid;
  flex: 1 0 auto;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 8px;
  row-gap: 8px;
}

.flexButton.flexButton {
  display: flex;
}
.button {
  composes: button from '../../@spaced-out/components/button/button.module.css';
  padding: 8px 12px;
  box-shadow: buttonDefaultShadow;
  flex-flow: row;
  height: 40px;
}

.title {
  font-weight: 600;
  font-size: 16px;
}
