/* prettier-ignore */
@value (
  colorBlue
  , colorTurquoise
  , colorWhite
  , colorLightGray
  , colorDarkGray
) from '../../../styles/colors.module.css';

@value buttonHorizontalPadding: 20px;
@value buttonDefaultBorderRadius: 5px;

@value buttonGlint: inset 0px 1px 0px #FFFFFF;
@value buttonInsetGlint: 0 1px 0 #ffffff;

@value buttonShadow: 0px 2px 3px rgba(0, 0, 0, 0.24), 0px 1px 8px rgba(0, 0, 0, 0.12);
@value buttonHoverShadow: 0px 6px 8px rgba(0, 0, 0, 0.24), 0px 3px 24px rgba(0, 0, 0, 0.12);
@value buttonInsetShadow: inset 0px 1px 1px rgba(0, 0, 0, 0.24), inset 0px 0px 4px rgba(0, 0, 0, 0.12);

@value buttonDefaultShadow: buttonShadow, buttonGlint;
@value buttonDefaultHoverShadow: buttonHoverShadow, buttonGlint;
@value buttonDefaultInsetShadow: buttonInsetShadow, buttonInsetGlint;

.button {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  padding: 14px buttonHorizontalPadding;
  height: 44px;
  text-align: center;
  font-size: 16px;
  border-radius: buttonDefaultBorderRadius;
  align-items: center;
  /* TODO (kyle): we need some sort of outline for accessbility. */
  /*outline: none;*/
  cursor: pointer;
}

@media (max-width: 668px) {
  .button {
    height: 34px;
    font-size: 14px;
    line-height: 1;
    padding: 10px 14px;
  }
}

.buttonWithShadow {
  composes: button;
  box-shadow: buttonShadow;
}
.buttonIcon {
  padding: 0 buttonHorizontalPadding 0 0;
}
.buttonIcon > .icon {
  width: 36px;
  min-width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  margin: 8px;
}

.buttonPrimary {
  composes: backgroundBlue from '../../../styles/colors.module.css';
  composes: buttonWithShadow;
}
.buttonDefault {
  composes: backgroundGray from '../../../styles/colors.module.css';
  composes: button;
  color: colorBlue;
  box-shadow: buttonDefaultShadow;
}
.buttonChecked {
  composes: buttonWithShadow;
  composes: buttonIcon;
  background: colorTurquoise;
  color: colorWhite;
}
.buttonUnchecked {
  composes: buttonWithShadow;
  composes: buttonIcon;
  color: colorTurquoise;
}
.button:hover {
  box-shadow: buttonHoverShadow;
}
.buttonDefault:hover {
  box-shadow: buttonDefaultHoverShadow;
}
.button:active {
  box-shadow: buttonInsetShadow;
}
.buttonDefault:active {
  box-shadow: buttonDefaultInsetShadow;
}
.button[disabled],
.button:disabled,
.button:disabled:hover,
.button:disabled:active,
.buttonDisabled,
.buttonDisabled:hover,
.buttonDisabled:active {
  cursor: default;
  background: colorLightGray;
  color: colorDarkGray;
  box-shadow: buttonDefaultInsetShadow;
  border-radius: buttonDefaultBorderRadius;
}
.children {
  flex: 1 1 auto;
  text-align: center;
}
