@value maxMobile from './breakpoints.module.css';

/* families */
.sansSerif {
  font-family: 'proxima-nova', 'Helvetica Neue', sans-serif;
}

.paragraph {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.text {
  composes: sansSerif;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.02em;
  font-style: normal;
  font-weight: normal;

  @media maxMobile {
    font-size: 12px;
  }
}

.message {
  composes: paragraph;
  font-size: 15px;
  line-height: 18px;

  @media maxMobile {
    font-size: 14px;
    line-height: 17px;
  }
}

.subtitle {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2;

  @media maxMobile {
    font-size: 12px;
    line-height: 17px;
  }
}

.timestamp {
  font-size: 12px;

  @media maxMobile {
    font-size: 10px;
  }
}

.title {
  font-size: 18px;
  letter-spacing: 0.02em;
}

.bold {
  font-weight: bold;
}
