@value maxMobile from 'styles/breakpoints.module.css';
@value (colorLightGray, colorDarkGray, colorBlue) from 'styles/colors.module.css';

.multiSelectRoot,
.jobMatchRoot {
  flex-flow: column;
  align-items: flex-start;

  & > * + * {
    margin-top: 16px;
  }
}

.jobMatchRoot {
  border: 2px solid colorLightGray;
  border-radius: 5px;
  padding: 20px;
  font-size: 14px;
  flex: 1 1 auto;

  @media maxMobile {
    flex: 1 1 auto;
    max-width: 500px;
  }
}

.titleRow {
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
}

.listItem {
  margin: 12px 0;
}

.jobRow {
  flex-flow: column;
}

.customJobResult {
  color: unset;
  font-size: 14px;
  text-decoration: none;
  margin-bottom: 12px;
}

.customJobResultWithLink {
  color: unset;
  font-size: 14px;
  text-decoration: none;
  margin-bottom: 12px;
  
    & a {
      color: colorBlue;
      text-decoration: none;
      font-weight: bold;
    }
  
}

.linkText {
  composes: bold title from 'styles/typography.module.css';
  color: colorBlue;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
}

.fileUploadButton {
  cursor: pointer;
}

.fileUploadInput {
  display: none;
}

.waitStartContainer,
.agentDropContainer {
  border-top: 1px solid #ddd;
  flex: 1 0 100%;
  flex-flow: column;
  margin-bottom: 24px;
}

.agentDropContainer {
  border-top-width: 0;
  border-bottom: 1px solid #ddd;
}

.waitStartMessage,
.agentDropMessage {
  justify-content: center;
  margin: 8px auto 12px;
  padding: 0 12px;
  font-size: 12px;
  color: #919191;
}
.agentDropMessage {
  margin: 12px auto 8px;
}

/* this apes the genesis small tertiary button styles */
.smallTertiaryButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px 10px;
  gap: 10px;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  margin:  0 auto;
  cursor: pointer;
}
.waitCancelButtonText {
  font-size: 13px;
  color: #17172a;
  letter-spacing: 0.01em;
  line-height: 120%;
}

.meetingNode {
  width: 100%;
  justify-content: center;
}