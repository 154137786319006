@value (minMobile, maxMobile) from 'styles/breakpoints.module.css';
@value (colorDarkGray, colorGreen) from 'styles/colors.module.css';

.container {
  flex-direction: column;
  align-items: center;

  @media maxMobile {
    flex: 1 0 auto;
    justify-content: center;
  }
}

.title {
  composes: bold title from 'styles/typography.module.css';
}

.tableTitle {
  composes: title;
  font-size: 25px;
  margin-bottom: 25px;
}

.tableContainer {
  composes: tableShadow from 'styles/shadows.module.css';
  width: 740px;
  min-height: 450px;
  border: 1px solid colorDarkGray;
  border-radius: 5px;

  @media maxMobile {
    width: 100%;
  }
}

.fieldTable {
  width: 100%;
  flex-direction: column;
}

.fieldTable > * {
  border-bottom: 1px solid colorDarkGray;
}

.column {
  flex: 1 0 0%;
  align-items: center;
}

.column:last-child {
  border-bottom: none;
}
 
.rowValue {
  composes: text from 'styles/typography.module.css';
  width: 50%;
  flex-grow: 1;
  margin-left: 2em;
}

.chatCompleteContainer {
  flex-direction: column;
  padding: 125px;
  
  @media maxMobile {
    padding: 125px 40px;
    flex: 1 0 0%;
  }
}

.chatComplete {
  max-height: 215px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.chatCompleteText {
  display: block;
  white-space: pre-wrap;
  margin-top: 25px;
  margin-bottom: 38px;

  @media maxMobile {
    margin-bottom: 30px;
  }
}

.checkmarkIcon {
  height: 20px;
  width: 20px;
  margin-right: 8px;
}
.checkmarkIcon path {
  fill: colorGreen;
}
