@value maxMobile from 'styles/breakpoints.module.css';
@value (colorDefaultText, colorLightGray, colorDarkGray, colorDarkerBorder, colorGray9, colorWhite, colorBlue) from 'styles/colors.module.css';

.desktopContainer {
  flex-flow: column;
  background-color: white;
  position: fixed;
  bottom: 85px;
  top: 115px;
  border-radius: 5px;
  overflow-y: auto;
  width: 668px;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);

  @media maxMobile {
    visibility: hidden;
  }
}

.mobileContainer {
  visibility: hidden;

  @media maxMobile {
    visibility: visible;
    background: rgba(0, 0, 0, 0.45);
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    position: fixed;
  }
}

.header {
  composes: bold title from 'styles/typography.module.css';
  color: colorBlue;
  padding: 32px;
  background-color: colorLightGray;
  cursor: pointer;
  height: 80px;
}

.mobileContent {
  flex-flow: column;
  background-color: colorWhite;
  position: fixed;
  top: 20px;
  left: 10px;
  right: 10px;
  height: 100%;
  border-radius: 5px;
  overflow-y: scroll;
  padding: 20px;
}

.mobileTopRow {
  justify-content: flex-end;

  & > * {
    font-weight: bold;
    color: colorBlue;
    margin-bottom: 12px;
    height: 12px;
    width: 12px;
  }
}

.docDownload {
  color: colorBlue;
  cursor: pointer;
  align-self: center;
  margin-top: 50%;
}

object {
  height: 100%;
}

img {
  height: 100%;
  object-fit: contain;
}
