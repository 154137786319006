@value (colorDefaultText, colorDarkerBorder) from 'styles/colors.module.css';
@value maxMobile from 'styles/breakpoints.module.css';

.jobDetailContainer {
    flex-flow: column;
    flex: 1 1 0%;
}

.jobDetail {
    flex: 1 1 0%;
    flex-flow: column;
    height: 100%;
    padding: 0 20px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */

    @media maxMobile {
        padding: 0;
    }

    & > * {
        margin-top: 16px;
    }
}

.jobDetail::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}


.jobDetailTitle {
    composes: bold title from 'styles/typography.module.css';
}

.jobDetailSubtitle {
    align-items: center;
    color: colorDefaultText;

    & > * {
        margin-right: 12px;
    }
}

.jobDetailDescription {
    display: unset;
    flex-flow: column;
    border-top: 1px solid colorDarkerBorder;
    padding: 12px 0;
}

.applyNow {
    justify-content: center;
    padding: 20px 0;

    & > * {
        text-decoration: none;
        width: 75%;
    }
}