@value (minMobile, maxMobile, widthMobile) from 'styles/breakpoints.module.css';
@value (colorGray3, colorGray6, colorLightGray, colorDarkGray, colorWhite, colorBlue, colorLightBlue) from 'styles/colors.module.css';
@value (buttonShadow) from '../../@spaced-out/components/button/button.module.css';

.root {
  flex-flow: column;
  align-items: stretch;
  height: 100%;

  @media maxMobile {
    padding: 0px 0 65px;
  }
}

.thread {
  flex: 1 1 1px;
}

.bannerMessage {
  composes: backgroundGray from 'styles/colors.module.css';
  padding: 12px 12px;
  font-size: 14px;
}

.textInputWrapper {
  composes: backgroundGray from 'styles/colors.module.css';
  height: 74px;
  padding: 12px 20px;
  max-width: widthMobile;

  @media maxMobile {
    position: fixed;
    bottom: 0;
    width: 100vw;
    padding: 10px 10px;
    height: calc(50px + 16px);
  }

  &.hasBotBranding {
    height: calc(50px + 16px + 10px);
    padding-bottom: 20px;
  }
}

.textInputInner {
  composes: flexGrow from 'styles/ui.module.css';
  position: relative;
  min-width: 0;
}

.textarea {
  composes: flexGrow from 'styles/ui.module.css';
  composes: message from 'styles/typography.module.css';
  composes: backgroundWhite from 'styles/colors.module.css';

  padding: 14px;
  resize: none;
  border: 1px solid colorLightGray;
  border-radius: 5px;
  box-shadow: inset 0px 1px 6px rgba(0, 0, 0, 0.25);
  outline: none;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media maxMobile {
    /* this font size prevents the input from forcing a ui zoom */
    font-size: 16px;
    padding: 10px;
    padding-right: 40px;
  }

  &:disabled {
    background-color: colorLightGray;
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.24),
      inset 0px 0px 4px rgba(0, 0, 0, 0.12);
    border: none;
  }
  &:focus {
    border-color: colorBlue;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.token {
  padding: 2px 6px;
  margin-right: 8px;
  background-color: colorLightBlue;
  color: colorBlue;
  border: 1px solid colorBlue;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
}

.removeToken {
  margin-left: 8px;
}

.dropdownContainer {
  composes: flexGrow from 'styles/ui.module.css';
  position: relative;
  flex-flow: column;
  min-width: 0;
  width: 100%;
}

.dropdownOptions {
  composes: flexGrow from 'styles/ui.module.css';
  composes: hover from 'styles/elevation.module.css';
  position: absolute;
  flex-flow: column;
  background-color: colorWhite;
  min-width: 100%;
  bottom: 50px;
  border-radius: 5px;
  max-height: 320px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.dropdownOptions::-webkit-scrollbar {
  display: none;
}

.dropdownOption {
  font-size: 16px;
  padding: 10px;
  color: colorGray3;
  cursor: pointer;
  border-radius: 0;
  font-weight: unset;

  &:hover {
    background-color: colorLightBlue;
  }

  & > * {
    text-align: left;
  }
}

.dropdownInputContainer {
  width: 100%;
  align-items: center;
}

.dropdownInput {
  border: none;
  flex: 1 1 0%;

  @media maxMobile {
    /* this font size prevents the input from forcing a ui zoom */
    font-size: 16px;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    opacity: 1;
    color: colorBlue;
  }
}

.caret {
  color: colorBlue;
  width: 10px;
  cursor: pointer;
}
.caratReversed {
  transform: rotate(180deg) scaleX(-1);
}

.button {
  display: flex;

  @media maxMobile {
    top: 0;
    right: 0;
    width: 50px;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  @media minMobile {
    margin-left: 16px;
    align-self: center;

    width: 48px;
    height: 48px;
    align-items: center;
    justify-content: center;
  }

  &.enabled {
    cursor: pointer;
  }
}

.senseBranding {
  position: absolute;
  width: 93px;
  height: 12px;
  bottom: 5px;
}

.removeFile {
  margin-right: 8px;
  cursor: pointer;
  align-items: center;

  & path {
    fill: colorDarkGray;
  }
}

.previewFile {
  color: colorBlue;
  margin-left: 8px;
  cursor: pointer;
}

.faqContainer {
  flex: 1 1 0%;
}

.faqList {
  flex-flow: column;
  margin: 0 0 25px 64px;

  & > * + * {
    margin-top: 10px;
  }
}

.faqQuestion {
  composes: bold title from 'styles/typography.module.css';
  height: 30px;
  padding: 16px 20px;
  font-size: 12px;
  align-items: center;
  color: colorBlue;
  cursor: pointer;
  border: 2px solid colorBlue;
  border-radius: 8px;
}

.faqTypeYourOwn {
  composes: bold title from 'styles/typography.module.css';
  font-size: 12px;
  color: colorDarkGray;
}

.gif {
  width: 100%;
}

.conversationContainer {
  overflow-y: auto;
  flex-flow: column nowrap;
  flex-grow: 1;
}
