@value (colorPink, colorWhite, colorPurple) from 'styles/colors.module.css';

.inboundMinContainerRight {
    composes: bold from 'styles/typography.module.css';
    align-self: flex-end;
    width: fit-content;
    max-width: 100%;
    padding: 8px 20px;
    border: 2px solid colorPink;
    border-radius: 55px;
    color: colorPurple;
    background-color: colorWhite;
    animation: enterRight 1s 1;
    cursor: pointer;
}

.inboundMinContainerLeft {
    composes: inboundMinContainerRight;
    align-self: flex-start;
    animation: enterLeft 1s 1;
}

.inboundMinContainerRight p,
.inboundMinContainerLeft p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@keyframes enterRight {
    0% {
        right: -200px;
        position: absolute;
    }

    100% {
        right: 0;
    }
}

@keyframes enterLeft {
    0% {
        left: -200px;
        position: absolute;
    }

    100% {
        left: 30px;
    }
}