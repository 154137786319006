@value (widthMobile, maxMobile, minMobile) from 'styles/breakpoints.module.css';
@value (
  colorBlue
  , colorWhite
  , colorDefaultText
) from 'styles/colors.module.css';

.header {
  composes: tooltip from 'styles/elevation.module.css';
  position: relative;
  z-index: 1;
  height: 65px;
  padding: 0 90px;
  align-items: center;
  background-color: white;

  &.clickableHeader {
    cursor: pointer;
  }

  & img {
    max-height: 35px;
  }

  & span {
    font-size: 24px;
    font-weight: bold;
  }

  @media maxMobile {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 45px;

    & img {
      max-height: 25px;
      margin: 0 auto;
    }
  }
}

/* Named inbound header */

.inboundHeader {
  background-color: #f7f7f7;
  flex-flow: row;
  justify-content: space-between;

  & .botName {
    margin: 12px 19px 10px;
  }

  & .closeButton {
    border: none;
    background: transparent;
    padding: 0 20px;
    cursor: pointer;
  }
}

.botName {
  flex-flow: column;
  padding: 3px;
  & > * {
    line-height: 1.4em;
    margin: 0;
  }
}
.botName h2 {
  font-weight: normal;
  font-size: 16px;
  color: #000;
}
.botName p {
  font-size: 14px;
  font-weight: 600;
  color: #767676;
}

/* Named desktop header */

.namedHeader {
  composes: backgroundGray from 'styles/colors.module.css';
  height: 80px;
  padding: 24px 45px;
  justify-content: space-between;
  border-bottom: 1px solid colorWhite;
}

.headerTitle {
  composes: title from 'styles/typography.module.css';
  margin: 0;
}

.headerSubtitle {
  composes: subtitle from 'styles/typography.module.css';
  composes: textGray from 'styles/colors.module.css';
  margin: 0;
}

.appControls {
  display: flex;
  justify-content: center;
  padding: 6px 6px;
  align-self: center;
  border-radius: 8px;
}

.headerTitles {
  flex-flow: column;
}

.desktopHeaderControls {
  justify-content: flex-end;
  position: relative;
  left: -6px;
}
.inboundHeaderControls {
  padding-right: 6px;
}

.faqButton {
  composes: bold title from 'styles/typography.module.css';
  height: 30px;
  padding: 12px;
  font-size: 12px;
  align-items: center;
  align-self: center;
  color: colorBlue;
  cursor: pointer;
  border: 1px solid colorBlue;
  border-radius: 8px;
  margin: 0 12px;
}

.controlIcon,
.closeButton {
  align-self: center;
  height: 20px;
  cursor: pointer;
}

.closeButton {
  color: colorBlue;
}

.verticalButton {
  position: relative;
}

.contextMenu {
  z-index: 1;
  position: absolute;
  right: 20px;
  top: 50px;
  width: 225px;
  border-radius: 4px;
  overflow: hidden;
  /* TODO(marcos):  is this reusable?; */
  filter: drop-shadow(0px 3px 24px rgba(0, 0, 0, 0.12)) drop-shadow(0px 6px 8px rgba(0, 0, 0, 0.24));
}

.contextMenu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 225px;
}
.contextMenu button {
  display: flex;
  width: 100%;
  line-height: 40px;
  border:  none;
  background-color: colorWhite;
  padding: 0 10px;
  color: #666;
  font-weight: bold;

  &:hover {
    /* todo(marcos):  is this color somewhere else in chatbot? */
    background-color: #EBF3FF;
    color: #444;
    cursor: pointer;
  }
}
